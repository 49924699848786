import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="map"
export default class extends Controller {
  static targets = ["map"]
  static values = {
    coordinates: Array
  }

  async connect() {
    this.initializeMap()
  }

  async setupMapKit() {
    console.log("waiting on mapkit...")
    if (!window.mapkit || window.mapkit.loadedLibraries.length === 0) {
      await new Promise(resolve => { window.initMapKit = resolve });
      console.log("mapkit loaded")
      delete window.initMapKit;
    }

    mapkit.init({
      authorizationCallback: function(done) {
        axios.get("/api/v4/mapkit_tokens").then(function(res) {
          var token = res.data;
          done(token);
        });
      }
    });

  }

  async initializeMap() {
    await this.setupMapKit()

    console.log("map initialization reached")
    if (typeof mapkit !== "undefined") {
      console.log("map initialization started")
      console.log(this.coordinatesValue)

      const { center, span } = this.calculateRegion(this.coordinatesValue);

      var map = new mapkit.Map(this.mapTarget.id, {
        showsZoomControl: true,
        showsMapTypeControl: true,
        showsCompass: mapkit.FeatureVisibility.Hidden,
        showsPointsOfInterest: true,
        center: new mapkit.Coordinate(center.lat, center.lng),
        region: new mapkit.CoordinateRegion(new mapkit.Coordinate(center.lat, center.lng), new mapkit.CoordinateSpan(span.latDelta, span.lngDelta)),
      });


      this.coordinatesValue.forEach(coord => {
        const annotation = new mapkit.MarkerAnnotation(
          new mapkit.Coordinate(coord.lat, coord.lng),
          {
            title: coord.title
          }
        );
        map.addAnnotation(annotation);
      });
    
      console.log("map initialization complete")
    }
  }

  calculateRegion(coordinates) {
    if (coordinates.length === 0) return {};

    let minLat = coordinates[0].lat;
    let maxLat = coordinates[0].lat;
    let minLng = coordinates[0].lng;
    let maxLng = coordinates[0].lng;

    coordinates.forEach(coord => {
      if (coord.lat < minLat) minLat = coord.lat;
      if (coord.lat > maxLat) maxLat = coord.lat;
      if (coord.lng < minLng) minLng = coord.lng;
      if (coord.lng > maxLng) maxLng = coord.lng;
    });

    const center = {
      lat: (minLat + maxLat) / 2,
      lng: (minLng + maxLng) / 2
    };

    const latDelta = maxLat - minLat;
    const lngDelta = maxLng - minLng;

    // Add a small padding to the span to ensure all points are within view
    const padding = 1;
    const span = {
      latDelta: latDelta + padding,
      lngDelta: lngDelta + padding
    };

    return { center, span };
  }
}
