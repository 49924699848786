/*** IMPORTS FROM imports-loader ***/
var d3 = require("d3");
var define = false;
var exports = false;

export async function inspectionSchedulesPreviewCount(
  structureIds,
  inspectionFormIds
) {
  if (structureIds.length > 0 && inspectionFormIds.length > 0) {
    const url = new URL(`${location.protocol}//${location.hostname}/api/v4/inspection_schedules/preview`);
    const params = new URLSearchParams(url.search)

    inspectionFormIds.forEach((elem, index) => {
      params.append('inspection_form_ids[]', elem)
    })
    structureIds.forEach((elem, index) => {
      params.append('structure_ids[]', elem)
    })

    url.search = params
    const res = await fetch(url, {
      credential: "same-origin",
    }).then((res) => res.json());
    return res.locations_count;
  } else {
    return -1;
  }
}

