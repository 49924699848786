/*** IMPORTS FROM imports-loader ***/
var d3 = require("d3");
var define = false;
var exports = false;

$(function() {
  // Manually opens the beacon via a user click
  $(".js-helpscout-beacon").on('click', function(e) {
    Beacon("open");
  });
});
