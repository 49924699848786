/*** IMPORTS FROM imports-loader ***/
var d3 = require("d3");
var define = false;
var exports = false;

$(document).ready(function() {
    /* -------------------------------------------------------------------------- */
  /* Setup Inspection Forms */
  /* -------------------------------------------------------------------------- */
  $(".js-sortable-items").sortable({
    containment: '.js-sortable-items',
    tolerance: 'pointer',
    items: ".line-item, .category:not(:first)"
  });


  $(".js-setup-form").on("focusin", ".ui-autocomplete-input", function() {
    var width = $(this).outerWidth();
    $(this).autocomplete({
        source: '/setup/line_items.json',
        minLength: 3,
        open: function() {
          $('.ui-autocomplete').css('width', width);
        }
    });
  });

  $(".remove_categories").click(function(e) {
    e.preventDefault();
    $(".inspection_form_elements .category").remove();
    $(".remove_categories").hide();
  });

  $(".inspection_form_elements").delegate(".category .delete_category", "click", function(e) {
    e.preventDefault();
    $(this).parents('.category').remove();
  });


  $(".js-setup-form").submit(function(e) {
    if ($(this).hasClass("submitted")) {
      return true;
    } else {
      e.preventDefault();

      // assign position to line items
      sortables = $(".js-form-items .line-item, .js-form-items .category");

      sortables.each(function(i, item) {
        if ($(item).is(".line-item")) {
          $(item).find("input.position").val(i);
        }
      });

      errors = false;

      categories = $(".js-form-items .category");
      categories.each(function(i, item) {
        name = $(item).find("input").val();
        if (name.replace(/\s/g,"") == "") {
          errors = true;
          // assign an error class
        }
      });

      if (errors) {
        alert("Please fill out all categories.");
        return false;
      } else {
        $(this).addClass("submitted");
        $(this).submit();
      }
    }
  });
});

