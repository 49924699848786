/*** IMPORTS FROM imports-loader ***/
var d3 = require("d3");
var define = false;
var exports = false;

const moment = require('moment');
import * as SignaturePad from "signature_pad"

$(function() {

  ///////////////////////////////
  //         SELECTORS         //
  ///////////////////////////////

  const $inspectionForm = $("#js-inspection-form");
  const $inspectionFormSubmitButton = $("#js-inspection-form-submit-button");

  const $endedAtField = $inspectionForm.find("#js-ended-at-field");
  const $rangeChoicePositionFields = $inspectionForm.find(".js-rating-choice-position-field")


  ///////////////////////////////
  //         HANDLERS          //
  ///////////////////////////////

  // NOTE: We listen for click of the submission button rather than form
  //   submission, as triggering a form submission inside a form submission
  //   listener would cause an infite loop.
  $inspectionFormSubmitButton.on("click", function(event) {
    event.preventDefault();
    setEndedAtField();
    $inspectionForm.submit();
  })

  $rangeChoicePositionFields.on("change", function() {
    const selectedValue = $(this).val();

    const $dataContainer = $(this).closest(".js-data-container");
    const $inputContainer = $(this).closest(".form-group");

    const rangeChoiceType = $dataContainer.data("range-choice-type");
    const rangeChoiceOptionsData = $dataContainer.data("range-choice-options");

    const selectedOptionData = rangeChoiceOptionsData[selectedValue];

    // Universal Fields:
    // ---------------------
    const $deficientField = $inputContainer.find("#inspection_inspection_items__deficient");
    $deficientField.val(selectedOptionData["deficient"]);

    const $labelField = $inputContainer.find("#inspection_inspection_items__range_choice_label");
    $labelField.val(selectedOptionData["label"]);

    // Type Specific Fields:
    // ---------------------
    if (rangeChoiceType == "percentage") {
      const $scoreField = $inputContainer.find("#inspection_inspection_items__score");
      $scoreField.val(selectedOptionData["decimal_score"]);
    }
    else if (rangeChoiceType == "points") {
      const $pointsField = $inputContainer.find("#inspection_inspection_items__points");
      $pointsField.val(selectedOptionData["points"]);
    }
  })

  // Initializes canvas so user can provide signature
  //
  // Due to complexities with getting it working, this
  // currently passes the signature data back thru the
  // form encoded in the Data URI format inside of a
  // hidden_field on the inspection_item
  //
  // In future iteration, this would ideally upload
  // the image/png to S3 to be processed off dyno.
  $(".js-signature-button").click(function(e) {
    var signatureContainer = $(this).parent();
    var signatureModalSelector = $(this).data("target");

    var wrapper = document.querySelector(signatureModalSelector),
      canvas = wrapper.querySelector(".js-signature"),
      signaturePad;

    signaturePad = new SignaturePad(canvas);

    // Listeners to modal buttons
    $(signatureContainer).find(".js-save-signature").click(function(e) {
      if (signaturePad.isEmpty()) {
        // noop - we do not ask user to enter signature
      } else {
        // Save Signature to hidden field
        var signatureDataURI = signaturePad.toDataURL('image/png');
        $(signatureContainer).find("input").val(signatureDataURI);

        // displays checkmark indicator
        $(signatureContainer).find("i").removeClass("hidden");
      }
      $(signatureModalSelector).modal("hide");
    });

    // Clears prior signature data and removes checkmark indicator
    $(signatureContainer).find(".js-clear-signature").click(function(e) {
      $(signatureContainer).find("input").val("");
      $(signatureContainer).find("i").addClass("hidden");
      signaturePad.clear();
    });
  });


  ///////////////////////////////
  //         FUNCTIONS         //
  ///////////////////////////////

  function setEndedAtField() {
    $endedAtField.val(moment().format());
  }

});

// Inspection Geolocation
// Used on web inspections to track latitude and longitude
$(function() {
  if($("#latitude").length > 0) {
    navigator.geolocation.getCurrentPosition(handle_geolocation_query);
  } 
});

function handle_geolocation_query(position) {
  $("#latitude").val(position.coords.latitude);
  $("#longitude").val(position.coords.longitude);

  $("#geolocation-not-found-message").addClass("hidden");
  $("#geolocation-success-message").removeClass("hidden");
}

