/*** IMPORTS FROM imports-loader ***/
var d3 = require("d3");
var define = false;
var exports = false;

import d3_queue from 'd3-queue'
import moment from 'moment'

function parameterUrl(jqSelector) {
  // returns a string representing the Analytics URL with proper parameters
  var selector = $(jqSelector);
  var apiUrl = "/api/v4/" + selector.data('api-endpoint');

  // List of supported API parameters
  var filterKeys = [ 'start-date', 'end-date', 'structure-ids', 'inspection-filter', "inspection-form-ids", "group-or-user-id", "user-ids", "status", "ticket-category-id", "assignee-type-id", "line-item-id", "decimal-places", "list-choice-id", "tag-ids" ];

  var hash = {};

  $.each(filterKeys, function(index, value) {

    if(selector.data(value) != undefined && !(selector.data(value) === "")) {

      // convert regular html key to URL-friendly version with underscore
      var tempKey = value.replace(/-/g, '_');
      hash[tempKey] = selector.data(value);
    }
  });

  apiUrl = apiUrl + ".json" + "?" + $.param(hash);

  return apiUrl;
}

function metricBundleLoad(jqSelector) {
  var apiEndpoint = $(jqSelector).data('api-endpoint');

  d3.json(parameterUrl(jqSelector), function(error, data) {
    if(error) {
      // No-op
    } else {
      $.each( data, function( key, value ) {
        $(jqSelector).find(`[data-metric-bundle='${key}']`).html(value);
      });

      $(jqSelector).find(".loading-lg").removeClass("loading-lg");
    }
  });
}

function metricChart(jqSelector) {
  var apiEndpoint = $(jqSelector).data('api-endpoint');
  var tooltipLabel = $(jqSelector).data('tooltip-label');
  var selector = d3.select(jqSelector);

  d3.json(parameterUrl(jqSelector), function(error, data) {
    if(error) {
      // console.warn(error);
    } else {
      var metricValue = data[apiEndpoint]['value'];
      var metricUnit = data[apiEndpoint]['unit'];

      if(metricValue == null) {
        // When there is no value, just show the unit
        // Example: No data, so the JSON unit is "-"
        $(jqSelector).html(metricUnit);
      } else if(metricUnit == null) {
        // Example: A count of inspections, 100
        $(jqSelector).html(metricValue);
      } else {
        // Example: 100%, results with both a value and unit
        $(jqSelector).html(metricValue + '' + metricUnit);
      }

      $(jqSelector).parent().removeClass("loading-lg");
    }
  });
}

/* This function will create a new table of data
 *
 * Requirements:
 *  1. div must wrap a table element
 *  2. table must have a thead and tbody
 *  3. the th's must have a data-d3-column key/value
 *     which specifies the JSON key to populate form the api call
 */
function tableChart(jqSelector) {
  const apiEndpoint = $(jqSelector).data('api-endpoint');
  const tableElement = $(jqSelector).find("table").first();

  const statusBox = $("<div class=\"alert hidden\"></div>");
  $(jqSelector).prepend(statusBox);

  const loadingSpinner = $("<div class=\"alert loading-lg\"></div>");
  $(jqSelector).prepend(loadingSpinner);

  const largeDateRangeWarning =
    $(jqSelector).data('large-date-range') ? $(jqSelector).data('large-date-range') : false;

  if (largeDateRangeWarning) {
    statusBox.html("This report may take a while to process, please consider downloading the Excel version instead.");
    statusBox.removeClass("hidden");
    statusBox.addClass("alert-warning");
  }

  d3.json(parameterUrl(jqSelector), function(error, data) {
    if(error) {
      statusBox.html("This report is taking too long to process, please consider downloading the Excel version using the Export button.");
      statusBox.addClass("alert-danger");
    } else if(data[apiEndpoint].length === 0) {
      statusBox.html($(jqSelector).data('empty-message'));
      statusBox.addClass("alert-info");
    } else {

      var table = d3.select(jqSelector).select("table"),
                    thead = table.select("thead"),
                    tbody = table.select("tbody");

      // Detecting the columns & value types
      // JS will fill in data and format it based upon the defined cols
      var columns = [];
      var valueFormat = {};
      $(jqSelector).find("thead th").each(function() {
        var column = $(this).data('d3-column');
        valueFormat[column] = $(this).data('result');
        columns.push(column);
      });

      // Creating rows based on number of data attributes returned
      var rows = tbody.selectAll("tr")
        .data(data[apiEndpoint])
        .enter()
        .append("tr");

      // Create td cells in each row
      // Fill in each cell with data based on the column
      var cells = rows.selectAll("td")
        .data(function(row) {
            return columns.map(function(column) {
              if(valueFormat[column] === "score") {
                return {column: column, value: row[column] + "%"};
              } else {
                return {column: column, value: row[column]};
              }
            });
        })
        .enter()
        .append("td")
        .html(function(d) { return d.value; });

      loadingSpinner.addClass("hidden");
      statusBox.addClass("hidden");

      return table
    }

    tableElement.addClass("hidden");
    loadingSpinner.addClass("hidden");
    statusBox.removeClass("hidden");
  });
}

function multiLineChart(jqSelector) {
  var apiEndpoint = $(jqSelector).data('api-endpoint');
  var timePeriod = $(jqSelector).data('time-period');
  var tooltipLabel = $(jqSelector).data('tooltip-label');
  var dataResult = $(jqSelector).data('result');
  var selector = d3.select(jqSelector);

  // Serially process json requests
  var q = d3_queue.queue(1);

  // Add jobs to queue. Specified as divs under the parent.
  $(jqSelector).children('.js-chart-queue').each(function(index, queuedOption) {
    q.defer(queuedD3Json, queuedOption);
  });

  // Wait for jobs to complete
  q.awaitAll(function(error, data) {
    nv.addGraph(function() {
      var chart = nv.models.lineChart()
          .x(function(d) { return d.date })
          .y(function(d) { return d.value})
          .showYAxis(true)
          .showXAxis(true)
          .duration(250);

      // Set axis + value formats to integer
      //chart.valueFormat(d3.format(',d'));
      //chart.yAxis.tickFormat(d3.format(',d'));
      chart.xAxis
        .tickFormat(function(d) {
          if(timePeriod === "month") {
            return d3.time.format("%b %Y")(new Date(d));
          } else {
            return d3.time.format("%b %d")(new Date(d));
          }
        });
      chart.xScale(d3.time.scale());
      chart.margin({left: 30, right: 30, bottom: 19});

      chart.color(d3.scale.category20().range());
      chart.pointSize(55);
      chart.interpolate("cardinal");

      var formatDate = d3.time.format.iso;

      // Needed to compute lowest min value to rescale y-axis
      var valueArray = [];

      data.forEach(function(dataSet) {
        dataSet['values'].forEach(function(d) {
          const dateAttrs = d.date.split('-').map(d => parseInt(d)) ;
          dateAttrs[1] = dateAttrs[1] - 1;
          d.date = formatDate.parse(moment(dateAttrs));
          d.value = +d.value;
          valueArray.push(+d.value);
        });

        dataSet['strokeWidth'] = 4;
      });

      if(dataResult === "score") {
        // Data represents a score (ie %), so
        // 0 is the lowest and 100 is the highest value
        chart.yAxis
         .tickValues([0, 50, 100])
         .scale().domain([0, 100]);
        chart.forceY([0,50, 100]);
      } else if(dataResult === "autoscaled") {
        // Used on zoomed in scoring graphs
        // Adjust y-axis min to allow for some padding as it gets close to 0
        var minY = d3.min(valueArray);
        var scaledMin = d3.max([0, minY - 5]);
        chart.forceY(scaledMin);
      }

      var svg = selector.append("svg");
      svg.datum(data) // Populate the svg element with data
        .call(chart);       // render chart

      // When the window is resized, update the chart so it fits
      nv.utils.windowResize(chart.update);

      // Remove the loading spinner since chart is finished loading
      $(jqSelector).parent().removeClass("loading-lg");

      return chart;
    });
  });
}

function barChart(jqSelector) {
  var apiEndpoint = $(jqSelector).data('api-endpoint');
  var timePeriod = $(jqSelector).data('time-period');
  var tooltipLabel = $(jqSelector).data('tooltip-label');
  var selector = d3.select(jqSelector);

  // Serially process json requests
  var q = d3_queue.queue(1);

  // Add jobs to queue. Specified as divs under the parent.
  $(jqSelector).children('.js-chart-queue').each(function(index, queuedOption) {
    q.defer(queuedD3Json, queuedOption);
  });

  // Wait for jobs to complete
  q.awaitAll(function(error, data) {
    nv.addGraph(function() {
      var chart = nv.models.multiBarHorizontalChart()
          .x(function(d) { return d.label })
          .y(function(d) { return d.value })
          .barColor(d3.scale.category20().range())
          .duration(250)
          .margin({left: 100})
          .stacked(false);

      chart.showControls(false);
      chart.showValues(true);
      chart.showLegend(false);

      // Set axis + value formats to integer
      chart.valueFormat(d3.format(',d'));
      chart.yAxis.tickFormat(d3.format(',d'));

      // Need to format data to support bar chart
      data.forEach(function(dataSet) {
        dataSet['label'] = dataSet['key'];
        dataSet['value'] = +dataSet['values'].value;
      });

      // Adjust margin to fit all labels
      // Properly increase margin to long labels fit
      var maxLength = d3.max(data, function(d) { return +d['label'].length });
      if(maxLength > 18) {
        chart.margin({left: maxLength*7});
      }

      // key represents a 'series' but we only have 1 series for this chrat
      var formattedData = [{key: tooltipLabel, values: data}];

      var svg = selector.append("svg");
      svg.datum(formattedData) // Populate the svg element with data
        .call(chart);       // render chart

      // When the window is resized, update the chart so it fits
      nv.utils.windowResize(chart.update);

      // Remove the loading spinner since chart is finished loading
      $(jqSelector).parent().removeClass("loading-lg");

      return chart;
    });
  });
}

// Call from d3.queue to fetch API data and include label
// This is used for graphs that require multiple API calls
// such as those which find the count of tickets by category
function queuedD3Json(queuedOption, callback) {
  var apiEndpoint = $(queuedOption).data('api-endpoint');
  d3.json(parameterUrl(queuedOption), function(error, data) {
    callback(null, {key: $(queuedOption).data("tooltip-label"), values: data[apiEndpoint]});
  });
}

function timeSeriesStackedBarChart(jqSelector) {
  var apiEndpoint = $(jqSelector).data('api-endpoint');
  var timePeriod = $(jqSelector).data('time-period');
  var selector = d3.select(jqSelector);

  // Serially process json requests
  var q = d3_queue.queue(1);

  // Add jobs to queue. Specified as divs under the parent.
  $(jqSelector).children('.js-chart-queue').each(function(index, queuedOption) {
    q.defer(queuedD3Json, queuedOption);
  });

  // Wait for jobs to complete
  q.awaitAll(function(error, data) {
    var formatDate = d3.time.format.iso;

    nv.addGraph(function() {
      // Create a new NVD3 graph
      var chart = nv.models.multiBarChart()
                    .margin({left: 20, bottom: 19})
                    .x(function(d) { return d.date })
                    .y(function(d) { return d.value})
                    .showYAxis(true)
                    .showXAxis(true)
                    ;

      // Create xAxis with date format, Month Day
      chart.xAxis
        .tickFormat(function(d) {
          if(timePeriod === "month") {
            return d3.time.format("%b %Y")(new Date(d));
          } else {
            return d3.time.format("%b %d")(new Date(d));
          }
        });

      chart.forceY(0);

      data.forEach(function(dataSet) {
        dataSet['values'].forEach(function(d) {
          const dateAttrs = d.date.split('-').map(d => parseInt(d)) ;
          dateAttrs[1] = dateAttrs[1] - 1;
          d.date = formatDate.parse(moment(dateAttrs));
          d.value = +d.value;
        });
      });

      // Setting Y-axis to integer scale
      chart.yAxis.tickFormat(d3.format(',f'));

      // fixes low number repeating on y axis
      // chart.yAxis.tickValues(d3.range(chart.yAxis.scale().domain()[0], chart.yAxis.scale().domain()[1]));

      var svg = selector.append("svg");
      svg.datum(data) // Populate the svg element with data
        .call(chart);       // render chart

      // When the window is resized, update the chart so it fits
      nv.utils.windowResize(chart.update);

      // Remove the loading spinner since chart is finished loading
      $(jqSelector).parent().removeClass("loading-lg");

      return chart;
    });
  });
}

function timeSeriesBarChart(jqSelector) {

  var apiEndpoint = $(jqSelector).data('api-endpoint');
  var tooltipLabel = $(jqSelector).data('tooltip-label');
  var timePeriod = $(jqSelector).data('time-period');
  var dataResult = $(jqSelector).data('result');
  var selector = d3.select(jqSelector);

  d3.json(parameterUrl(jqSelector), function(error, data) {

    if(error) {
      // console.warn(error);
    } else {
      var formatDate = d3.time.format.iso;

      nv.addGraph(function() {
        // Create a new NVD3 graph
        var chart = nv.models.discreteBarChart()
                      .margin({left: 20, bottom: 19})
                      .x(function(d) { return d.date })
                      .y(function(d) { return d.value})
                      .showYAxis(true)
                      .showXAxis(true)
                      .showValues(true)
                      ;

        // Create xAxis with date format, Month Day
        chart.xAxis
          .tickFormat(function(d) {

            if(timePeriod === "month") {
              return d3.time.format("%b %Y")(new Date(d));
            } else {
              return d3.time.format("%b %d")(new Date(d));
            }
          });

        // Formatting data from API to:
        // * parse date
        // * set value as a Number
        // * set bar color
        data[apiEndpoint].forEach(function(d) {
          const dateAttrs = d.date.split('-').map(d => parseInt(d)) ;
          dateAttrs[1] = dateAttrs[1] - 1;
          d.date = formatDate.parse(moment(dateAttrs));
          d.value = +d.value;
          d.color = "#1f77b4";
        });

        // Determine what the maximum Y value is. This will be used to
        // scale the Y-axis
        var maxY = d3.max(data[apiEndpoint], function(d) { return +d.value; });

        // Autoscale the chart margin if the Y-axis will have more than 3 digits
        if(maxY > 999) {
          chart.margin({left: 30});
        }

        // Set Y-axis to a minimum of 0 and max of maxY
        chart.yAxis
          .scale().domain([0, maxY]);

        // Force the minimum Y-axis value to 0
        chart.forceY(0);

        // Setting Y-axis and value (such as tooltip) formatters
        chart.yAxis.tickFormat(d3.format(',f'));
        chart.valueFormat(d3.format('d'));

        // Add tooltip data to chart_data so NVD3 picks it up
        var chart_data = [{key: tooltipLabel, values: data[apiEndpoint]}];

        var svg = selector.append("svg");
        svg.datum(chart_data) // Populate the svg element with data
          .call(chart);       // render chart

        // When the window is resized, update the chart so it fits
        nv.utils.windowResize(chart.update);

        // Remove the loading spinner since chart is finished loading
        $(jqSelector).parent().removeClass("loading-lg");

        return chart;
      });
    }
  });
}

function timeSeriesChart(jqSelector) {

  var apiEndpoint = $(jqSelector).data('api-endpoint');
  var tooltipLabel = $(jqSelector).data('tooltip-label');
  var dataResult = $(jqSelector).data('result');
  var timePeriod = $(jqSelector).data('time-period');
  var selector = d3.select(jqSelector);

  d3.json(parameterUrl(jqSelector), function(error, data) {

    if(error) {
      // console.warn(error);
    } else {
      var formatDate = d3.time.format.iso;

      nv.addGraph(function() {

        // Create a new NVD3 graph
        var chart = nv.models.lineChart()
                      .x(function(d) { return d.date })
                      .y(function(d) { return d.value})
                      .showLegend(false)
                      .showYAxis(true)
                      .showXAxis(true)
                      ;

        chart.color(["#1f77b4"]);
        chart.pointSize(55);
        chart.interpolate("cardinal");
        chart.margin({left: 20, bottom: 19});

        // Create X-axis and apply scaling
        chart.xAxis
          .tickFormat(function(d) {
            if(timePeriod === "month") {
              return d3.time.format("%b %Y")(new Date(d));
            } else {
              return d3.time.format("%b %d")(new Date(d));
            }
          });
        chart.xScale(d3.time.scale());
        chart.padData(true);

        // Formatting data from API to:
        // * parse date
        // * set value as a Number
        // * set point size
        // * set line color
        data[apiEndpoint].forEach(function(d) {
          const dateAttrs = d.date.split('-').map(d => parseInt(d)) ;
          dateAttrs[1] = dateAttrs[1] - 1;
          d.date = formatDate.parse(moment(dateAttrs));
          d.value = +d.value;
          d.color = "#1f77b4";
        });

        // Determine Y-axis scaling
        if(dataResult === "score") {
          // Data represents a score (ie %), so
          // 0 is the lowest and 100 is the highest value
          chart.yAxis
           .tickValues([0, 50, 100])
           .scale().domain([0, 100]);
          chart.forceY([0,50, 100]);
        } else if(dataResult === "autoscaled") {
          // Used on zoomed in scoring graphs
          // Adjust y-axis min to allow for some padding as it gets close to 0
          var minY = d3.min(data[apiEndpoint], function(d) { return +d.value; });
          var scaledMin = d3.max([0, minY - 5]);
          chart.forceY(scaledMin);
        }

        // Add tooltip to the chart data for NVD3 to grab it
        var chart_data = [{key: tooltipLabel, strokeWidth: 4, values: data[apiEndpoint]}];

        var svg = selector.append("svg");
        svg.datum(chart_data) // Populate the svg element with data
          .call(chart);       // render chart

        // When the window is resized, update the chart so it fits
        nv.utils.windowResize(chart.update);

        $(jqSelector).parent().removeClass("loading-lg");

        return chart;
      });
    }
  });
}

// Special code from SO to detect if an element is within viewport
function inViewport(el) {
  var r, html;
  if ( !el || 1 !== el.nodeType ) { return false; }
  html = document.documentElement;
  r = el.getBoundingClientRect();

  return ( !!r
    && r.bottom >= 0
    && r.right >= 0
    && r.top <= html.clientHeight
    && r.left <= html.clientWidth
  );
}

// Async loading of charts that are inviewport
function checkElements() {
  $(".js-metric-bundle:not(.api-loaded)").each(function(index, value) {
    if(inViewport(this)) {
      $(this).addClass("api-loaded");
      metricBundleLoad(this);
    }
  });

  $(".js-chart-metric:not(.api-loaded)").each(function(index, value) {
    if(inViewport(this)) {
      $(this).addClass("api-loaded");
      metricChart(this);
    }
  });

  $(".js-chart-time-series:not(.api-loaded)").each(function(index, value) {
    if(inViewport(this)) {
      $(this).addClass("api-loaded");
      timeSeriesChart(this);
    }
  });

  $(".js-chart-time-series-bar:not(.api-loaded)").each(function(index, value) {
    if(inViewport(this)) {
      $(this).addClass("api-loaded");
      timeSeriesBarChart(this);
    }
  });

  $(".js-chart-time-series-stacked-bar:not(.api-loaded)").each(function(index, value) {
    if(inViewport(this)) {
      $(this).addClass("api-loaded");
      timeSeriesStackedBarChart(this);
    }
  });

  $(".js-chart-table:not(.api-loaded)").each(function(index, value) {
    if(inViewport(this)) {
      $(this).addClass("api-loaded");
      tableChart(this);
    }
  });

  $(".js-chart-bar:not(.api-loaded)").each(function(index, value) {
    if(inViewport(this)) {
      $(this).addClass("api-loaded");
      barChart(this);
    }
  });

  $(".js-chart-multi-line:not(.api-loaded)").each(function(index, value) {
    if(inViewport(this)) {
      $(this).addClass("api-loaded");
      multiLineChart(this);
    }
  });
}

// Bind to scroll event to check if charts are in viewport and need to be loaded
$(window).bind("scroll", function() {
  checkElements();
});

// Check for elements that need to be loaded on page load
$(document).ready(function() {
  checkElements();
});

