/*** IMPORTS FROM imports-loader ***/
var d3 = require("d3");
var define = false;
var exports = false;

$(function() {
  window.detectWebGLContext = function() {
    var canvas = document.createElement('canvas');
    var gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

    return !!(gl && gl instanceof WebGLRenderingContext);
  }
})

