/*** IMPORTS FROM imports-loader ***/
var d3 = require("d3");
var define = false;
var exports = false;

$(function() {

  $('.js-avatar-initials').each(function() {
    var colors = ["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50", "#f1c40f", "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d"];

    var avatarElement = $(this),

    avatarWidth = avatarElement.attr('width'),
    avatarHeight = avatarElement.attr('height'),
    
    initials = avatarElement.data('initials'),

    charIndex = initials.charCodeAt(0) - 65,
    colorIndex = Math.abs(charIndex % 19);

    avatarElement.css({
      'background-color': colors[colorIndex],
      'width': avatarWidth,
      'height': avatarHeight,
      'font-size' : avatarWidth / 2 + "px",
      'font-family': "ui-rounded, 'SF Pro Rounded', system-ui, Arial, sans-serif",
      'font-weight': 500,
      'color': '#FFF',
      'textAlign': 'center',
      'lineHeight': avatarHeight + 'px',
      'borderRadius': '50%'
    })
    .html(initials);
  });
});

