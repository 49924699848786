/*** IMPORTS FROM imports-loader ***/
var d3 = require("d3");
var define = false;
var exports = false;

export default class Immutable {
  static move(array, firstIndex, secondIndex) {
    let firstObject = array[firstIndex]
    let insertIndex = firstIndex < secondIndex ? secondIndex - 1 : secondIndex

    let newArray = this.remove(array, firstIndex)
    newArray = this.insert(newArray, insertIndex, firstObject)

    return { newIndex: insertIndex, newArray: newArray }
  }

  static moveSection(array, sectionStart, sectionEnd, targetIndex) {
    let newIndex, newArray
    let source = array[sectionStart]

    if(sectionStart < targetIndex)
      newArray = [
        ...array.slice(0, sectionStart),
        ...array.slice(sectionEnd, targetIndex),
        ...array.slice(sectionStart, sectionEnd),
        ...array.slice(targetIndex)
      ]
    else
      newArray = [
        ...array.slice(0, targetIndex),
        ...array.slice(sectionStart, sectionEnd),
        ...array.slice(targetIndex, sectionStart),
        ...array.slice(sectionEnd)
      ]

    newIndex = newArray.findIndex(object => source === object)
    return { newArray: newArray, newIndex: newIndex }
  }


  static insert(array, index, object) {
    return [
      ...array.slice(0, index),
      object,
      ...array.slice(index)
    ]
  }

  static remove(array, start, end) {
    if(end)
      if(end === -1)
        return array.slice(0, start)
      else
        return [...array.slice(0, start), ...array.slice(end)]
    else
      return array.slice(0, start).concat(array.slice(start + 1))
  }

  static update(array, index, newProps) {
    let newObject = Object.assign({}, array[index], newProps)

    return [ 
      ...array.slice(0, index),
      newObject,
      ...array.slice(index + 1)
    ]
  }
}

