// Entry point for the build script in your package.json

import {} from 'jquery-ujs'
// Add Axios globally for access in non-webpacked scripts
window.axios = require('./vendor/axios.min.js').axios

import "core-js/stable"
import "whatwg-fetch"
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-pro/css/all.min.css'
import "./vendor/jquery-ui-1.10.1.custom.min"

import "./vendor/d3-v3.5.16.min"
import "./vendor/nv.d3.v1.8.1"
import "./vendor/nv.d3.css"

import Chartkick from 'chartkick';
import "chart.js"

import "./vendor/jquery.tablesorter.min"
import "./vendor/jquery.tablesorter.widgets.min"
import "./vendor/tablesorter.theme.bootstrap"

import "./vendor/jquery.iframe-transport"
import "blueimp-file-upload"
import "./vendor/jquery.highlight"

// When we upgrade to 4.0.6-rc.1
// import "select2/dist/css/select2.min.css"
// import "select2/dist/js/select2.min.js"

// For 3.5.1
import "select2/select2"
import "select2/select2.css"
import "select2-bootstrap-css/select2-bootstrap.min.css"

import "./vendor/daterangepicker.min.js"
import "./vendor/daterangepicker-bs3.css"
import "./lib/app-custom16"
import "./lib/report-export.js"
import "./lib/helpscout-beacon.js"
import "./lib/d3.analytics170110"

import "./lib/detect_capabilities"
import "./lib/attachment-files"
import "./lib/perform-inspection"
import "./lib/ticket"
import "./lib/rating"
import "./lib/users"
import "./lib/surveys"
import "./lib/alerts"
import "./lib/ticket_automation"

import "./controllers"

var componentRequireContext = require.context("./components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

// Make jQuery available in non-webpacked scripts
Object.assign(window, { $, jQuery, jquery })
