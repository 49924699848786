/*** IMPORTS FROM imports-loader ***/
var d3 = require("d3");
var define = false;
var exports = false;

import moment from "moment"

$(function() {
  // Form Section Toggle
  //
  // Add 'class' and 'data-section' attributes to any radio inputs,
  // and class='form-section' to any toggle-able sections.
  //
  // Ex.
  //
  //    <input type="radio" class="form-section-toggle" data-section=".sectionA">
  //    <input type="radio" class="form-section-toggle" data-section=".sectionB">
  //
  //    <div class="form-section sectionA"></div>
  //    <div class="form-section sectionB"></div>
  //
  $("input[type='radio'].form-section-toggle").each(function(index, radio) {
    var $radio = $(radio)

    $radio.change(function() {
      var sectionSelector = $(this).data("section")

      disable(".form-section")
      enable(sectionSelector)
    })

    if($radio.attr("checked"))
      enable($radio.data("section"))

    function disable(selector) {
      $(selector).hide()
      $(selector).find("input, select, textarea").attr("disabled", true)
    }

    function enable(selector) {
      $(selector).show()
      $(selector).find("input, select, textarea").removeAttr("disabled")
    }
  })

  $(".datepicker").datepicker({
    dateFormat: 'yy-mm-dd'
  });

  $(".js-datepicker").datepicker({
    dateFormat: 'yy-mm-dd'
  });

  $(".js-single-datepicker").daterangepicker({
    singleDatePicker: true,
    timePicker: false,
    format: 'YYYY-MM-DD'
  });

  $(".js-range-datepicker").each(function() {
    var datePicker   = $(this)
    var rangeOptions = {
      default: {
       'Today': [moment(), moment()],
       'This week': [moment().startOf('week'), moment().endOf('week')],
       'This month': [moment().startOf('month'), moment().endOf('month')],
       'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      future: {
       'This week': [moment().startOf('week'), moment().endOf('week')],
       'Next week': [moment().startOf('week').add(1, "week"), moment().endOf('week').add(1, "week")],
       'This month': [moment().startOf('month'), moment().endOf('month')],
       'Next month': [moment().add(1, "month").startOf('month'), moment().add(1, "month").endOf('month')]
      }
    }

    var option = datePicker.data("ranges")
    var ranges = rangeOptions[option] || rangeOptions.default

    // Supports Anytime option by not enforcing a default start and end date.
    // This is needed to prevent the picker from trying to init nil dates.
    var datePickerOptions = {
      default: {
        format: 'YYYY-MM-DD',
        startDate: moment($(".js-range-datepicker").data('start-date')),
        endDate: moment($(".js-range-datepicker").data('end-date')),
        ranges: ranges,
        locale: {
          customRangeLabel: 'Custom',
          daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr','Sa'],
          firstDay: 1
        }
      },
      anytime: {
        ranges: rangeOptions.default
      }
    }

    var datePickerParams = datePickerOptions[option] || datePickerOptions.default;

    datePicker.daterangepicker(datePickerParams, function(start, end, label) {
      // Set hidden date range filters
      $(".js-range-datepicker").parent().find(".js-start-date-filter").val(start.format('YYYY-MM-DD'));
      $(".js-range-datepicker").parent().find(".js-end-date-filter").val(end.format('YYYY-MM-DD'));

      // Set link name to new date range
      $(".js-range-datepicker").html(start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD'));
    });
  })

  $('.js-range-datepicker').on('apply.daterangepicker', function(ev, picker) {
    $(".js-submit-on-change select").change();
  });

  // Anytime datepicker button. Clears date back to anytime.
  $('.js-range-datepicker-anytime').on('click', function(ev, picker) {
    $(this).parent().find(".js-start-date-filter").val("");
    $(this).parent().find(".js-end-date-filter").val("");
    $(".js-range-datepicker").html("Anytime");
    $(".js-submit-on-change select").change();
  });

  // Allow form to toggle between enabled/show and disabled/hide elements
  // need group-a and group-b tags
  $(".js-disable-hide").click(function(e) {
    e.preventDefault();

    var show_selector = "." + $(this).data('selector');
    var hide_selector = $(this);

    // handle case where the hide elements are defined
    if ($(this).data('hide-selector') !== undefined) {
      hide_selector = "." + $(this).data('hide-selector');
    }

    // hide and disable items
    $(hide_selector).addClass("hide").find(':input').prop('disabled', true);

    // toggle to show and enable elements in selector
    $(show_selector).removeClass("hide").find(":input").prop('disabled', false);
  });

  $(".js-show-hide").each(function(index, element){
    if ($(this).attr('data-hidden-text')){
      $(this).data('visible-text', $(this).html());
    } else {
      $(this).data('hidden-text', $(this).html());
    }
  });

  $(".js-show-hide").on('update-text', function() {
    var selector = "." + $(this).data('selector');
    var hiddenText = $(this).data('hidden-text');
    var visibleText = $(this).data('visible-text');

    if ($(selector).is(":visible")) {
      $(this).html(visibleText);
    }
    else {
      $(this).html(hiddenText);
    }
  });

  $('body').on('click', ".js-show-hide", function() {
    var selector = $(this).data('selector');
    $("." + selector).slideToggle(400, function() {
      $(".js-show-hide[data-selector='"+selector+"']").trigger('update-text');
    });
    return false;
  });

  $(".js-fill-text").click(function(e) {
    e.preventDefault();
    var textDestination = $(this).data('text-destination');
    var selector = $(this).data('selector');
    var currentText = $("." + textDestination).val();

    if (currentText.length === 0) {
      $("." + textDestination).val($(this).data('text-value'));
    } else {
      $("." + textDestination).val(currentText + ", " + $(this).data('text-value'));
    }

    $(this).hide();
    $("." + selector).show(400);
  });

  // Flip text
  // Ability to have text be toggled in place
  $(".js-flip-text").each(function(index, element) {
    $(this).data('visible-text', $(this).html());
    $(this).data('visible', true);
  });

  $(".js-flip-text").click(function(e) {
    e.preventDefault();

    var isVisible = $(this).data('visible');
    if (isVisible){
      $(this).html($(this).data('hidden-text'));
    }
    else {
      $(this).html($(this).data('visible-text'));
    }
    $(this).data('visible', !isVisible);
  });

  // Default for all select2
  $(".select2").select2({
    allowClear: true,
    width: 'resolve'
  });

  $(".select2-filter").select2({
    allowClear: true
  });

  // Select2 with rounded dropdown
  $(".select2-links-dropdown").select2({
    width: '100%',
    dropdownAutoWidth: true
  }).on("select2-open", function() {
    $(".select2-drop").addClass("select2-bordered-dropdown");
  }).change(function () {
    window.location.href = $(this).val();
  });

  $(".select2-multiple").select2({
    multiple: true
  });

  $('.js-inspection-event-edit').click(function (e) {
    window.location.href = $(this).data('destination-path');
    e.preventDefault();
  });

  $('.js-tooltip').tooltip();
  $(".js-popover-left-hover").popover({ placement : 'left', container: 'body', trigger: 'hover'});
  $('.field_with_errors').parent(".form-group").addClass('has-error');

  $('.btn-auto-disable').click(function() {
    // show spinner to make sure the user knows an action is taking place
    $(".loading").show();

    // Disable button from double clicks and submit form as usual
    $(this).prop('disabled', true);
    $(this).parents('form').submit();
  });

  // Turns btn-group into a form element
  // Define:
  //   * a data 'destination-id' on the btn-group which is the form element id to alter
  //   * a data 'selection-value' on the <a> tags which represent the selection choice
  $('.js-btn-group-form-element a').click(function (e) {
    var btn_group_element = $(this).closest('.js-btn-group-form-element');
    var destination_id = btn_group_element.data('destination-id');
    var selection_choice = $(this).data('selection-value');
    $('#' + destination_id).val(selection_choice);
    $('#' + destination_id).change();

    btn_group_element.find('a').removeClass('active');
    btn_group_element.find('button').removeClass('active');
    $(this).addClass("active");

    e.preventDefault();
  });

  // Changes btn-group dropdown-toggle text to the selected item
  $('.js-btn-group-form-element .js-btn-toggle a').click(function (e) {
    var btn_group_element = $(this).closest('.js-btn-group-form-element');
    btn_group_element.find('a').removeClass('active');
    btn_group_element.find('button').removeClass('active');

    var btn_toggle = btn_group_element.find(".dropdown-toggle");

    if(btn_toggle !== undefined){
      btn_toggle.addClass("active");
      var selected_name = $(this).html();
      btn_toggle.html(selected_name + " <span class='caret'></span>");
    }
  });

  // Users Notification Editing/Dependency
  // Can view inspections
  // - Can perform
  // - Can view detailed locations
  // - Can view private inspections
  // - Any inspection emails
  // Can access tickets
  // - Can create tickets
  // - Can view unassigned tickets
  // - Can change ticket status
  // - Any ticket emails
  // No emails - disable all checkboxes
  // Can view unassigned tickets
  // - [email] Unassigned ticket is created
  //
  // Users Notification Editing
  // Goal is to disable/gray out dependent sections when it's dependency is unchecked
  // Also to enable them when it is checked again
  // In order to reduce the number of additional fields in the html, before submission,
  // enable any dependent-disabled fields so that their values will properly be submitted

  ////
  // Manage all dependencies
  // Put a unique class on the checkbox that something is dependent on
  // Then on that dependent checkbox put `js-dependent` class and a
  // `data-dependent` attribute which contains the unique class(es) you set up (comma seperated).
  $('.js-dependent').on('checkDependency', function() {
    var dependencyClasses = $(this).data('dependent').split(",");
    var isDisabled = false;

    $.each(dependencyClasses, function(i, className) {
      // Get value of checkbox for dependency
      var isChecked = $("."+className).prop('checked');
      // It becomes disabled if one of them isn't checked
      isDisabled = isDisabled || !isChecked;
    });

    // Update disabled
    $(this).prop('disabled', isDisabled);
    // Update the parent label's class
    $(this).parent('label').toggleClass('text-muted', isDisabled);
    // Only if it is disabled do we want to uncheck it.
    // Leave it unchecked the other way.
    if (isDisabled) { $(this).prop('checked', false); }
  });
  // Make each dependency trigger `checkDependency`
  $('.js-dependent').each(function(i, item) {
    var dependent = item;
    // Grab all the dependencies
    var dependencyClasses = $(item).data('dependent').split(",");
    // Loop through each and set them up to trigger `checkDependency`
    $.each(dependencyClasses, function(i, className) {
      $("."+className).on('change', function() {
        $(dependent).trigger('checkDependency');
      });
    });
  });
  // When page load, make sure everything is up to date
  $('.js-dependent').trigger('checkDependency');

  // Before the form is submitted, make sure everything everything is enabled
  $('.js-dependent').parents('form').on('submit', function() {
    $('.js-dependent').prop('disabled', false);
  });

  // Setup / Schedules
  // When the frequency_type changes then change the js-* divs to show the right pane
  // and disable the inputs in the other option as a safety for form submission
  $('#frequency_type').change(function() {
    // grab the current value
    var val = $("#frequency_type option:selected").val();

    // Hide both weekly and monthly options at first
    $(".js-weekly-options").slideUp();
    $(".js-monthly-options").slideUp();

    // Display the correct weekly or monthly pane
    // Set hidden value for the actual schedule frequency
    if (val === "0"){
      $('#schedule_freq').val('0');
      display_weekly_schedule_options();
    }
    else if (val === "1") {
      $('#schedule_freq').val('1');
      display_monthly_by_week_day_schedule_options();
      $("#monthlyDayOfWeekRadio").prop("checked", true);
    }
  });

  $("input[name=monthlyRadios]:radio").change(function () {
    var radioFrequencyChoice = $(this).val();
    $('#schedule_freq').val(radioFrequencyChoice);

    if (radioFrequencyChoice === '1') {
      // Day of Week
      // disable all inputs for Day of Month
      $(".js-monthly-month-day-options :input").attr("disabled", true);
      // enable all inputs for Day of Week
      $(".js-monthly-week-day-options :input").attr("disabled", false);
    }
    else if (radioFrequencyChoice === '2') {
      // Day of Month
      // disable all inputs for Day of Week
      $(".js-monthly-week-day-options :input").attr("disabled", true);
      // enable all inputs for Day of Month
      $(".js-monthly-month-day-options :input").attr("disabled", false);
    }
  });

  // On load need to trigger it to so that it shows the right section
  $("#schedule_days_of_week_offset").trigger('change');

  function display_weekly_schedule_options() {
    $(".js-weekly-options :input").attr("disabled", false);
    $(".js-monthly-options :input").attr('disabled', true);

    $(".js-weekly-options").prependTo('.js-options');
    $(".js-weekly-options").slideDown();
  };

  function display_monthly_by_week_day_schedule_options() {
    $(".js-monthly-options :input").attr("disabled", false);
    $(".js-monthly-month-day-options :input").attr("disabled", true);
    $(".js-weekly-options :input").attr("disabled", true);

    $(".js-monthly-options").prependTo('.js-options');
    $(".js-monthly-options").slideDown();
  };

  function initialize_schedules_setup() {
    var frequency_type = $('#schedule_freq').val();

    // Hide both weekly and monthly options at first
    $(".js-weekly-options").slideUp();
    $(".js-monthly-options").slideUp();

    if (frequency_type === "0") {
      display_weekly_schedule_options();
    } else if (frequency_type === "1") {
      display_monthly_by_week_day_schedule_options();
    } else if (frequency_type === "2") {
      // Monthly by date was chosen
      $(".js-monthly-options :input").attr("disabled", false);
      $(".js-monthly-week-day-options :input").attr("disabled", true);
      $(".js-weekly-options :input").attr("disabled", true);

      $(".js-monthly-options").prependTo('.js-options');
      $(".js-monthly-options").slideDown();
    }
  };
  initialize_schedules_setup();

  // On submit, remove the div unless it is selected
  $('.js-schedule-form').submit(function(){
    var val =  $("#schedule_freq").val();
    if (val != "0") {
      $(".js-weekly-options").remove(); }
    if (val != "1") {
      $(".js-monthly-week-day-options").remove(); }
    if (val != "2") {
      $(".js-monthly-month-day-options").remove(); }
  });

  $(".js-submit-on-change select").change(function(e) {
    $(".loading").show();
    $(this).parents("form").submit();
  });

  $(".js-submit-on-change input").change(function(e) {
    $(".loading").show();
    $(this).parents("form").submit();
  });

  $('.js-ticket-assignee').on('ajax:success', function(event, xhr, status, error) {
    var assigneeName = xhr['ticket']['assignee'];
    if (assigneeName != null) {
      $(".js-ticket-assignee-label").html(assigneeName['name']);
    } else {
      $(".js-ticket-assignee-label").html("No one");
    }

    // hide error message if user tried another selection
    $(this).find(".alert-danger").hide();

    // close modal
    $("#assign-ticket-modal").modal("hide");

    // Hide previous successful alerts so they don't stack up
    $(".js-alert-box .alert-success").hide();

    // copy success alert and display message
    $(".js-alert-box .alert-success").first().clone().show().appendTo(".js-alert-box").find(".js-alert-message").html("The ticket's assignment has been updated.");

  });

  $('.js-ticket-assignee').on('ajax:error', function(event, xhr, status, error) {
    $(this).find(".alert-danger").show();
  });

  $('.js-ticket-category').on('ajax:success', function(event, xhr, status, error) {
    var categoryName = xhr['ticket_category']['name'];
    $(this).find('.js-ticket-category-label').html(categoryName).effect("highlight", {color: '#5cb85c'}, 1000);
    $(this).parent().parent().find('.js-show-hide').click();
  });

  $('.js-ticket-category').on('ajax:error', function(event, xhr, status, error) {
    $(this).find('.js-ticket-category-label').effect("highlight", {color: 'red'}, 2000);
  });

  // Updates table's cached values when clicked (i.e. when triggering a sort)
  // When values are loaded in asynchronously, the table needs to be aware
  // of the new values to sort
  $(".js-table-sortable thead").click(function() {
    $(this).parent(".js-table-sortable").trigger("update");
  });

  // Default sortable table
  $(".js-table-sortable").tablesorter({
    theme : "bootstrap",
    widthFixed: true,
    ignoreCase: true,
    headerTemplate : '{content} {icon}',
    widgets : [ "uitheme", "filter", "zebra" ],

    widgetOptions : {
      zebra : ["even", "odd"],
      filter_columnFilters: false,
    }
  });

  // Reports / Feed
  // Async load inspection modal window
  function asyncLoadInspectionModal(selector) {
    $('.loading').show();

    // async url endpoint
    var modal_contents_url = $(selector).attr('data-remote-href');

    $.ajax({
      url: modal_contents_url,
      dataType: "script",
      success: function() {
        $('.loading').hide();
      }
    });

    // Change address bar to inspection url
    window.history.replaceState("Inspection", "Inspection", $(selector).data('history-href'));
  }


  // Async load modals when clicked
  // Also needed for table rows added in infinite scroll
  $('.js-infinite-scroll').on('click', '.js-async-modal', function() { asyncLoadInspectionModal(this) });

  // Used on links to display the link to an inspection as a modal
  // Requires template modal code to be present on the page.
  $('.js-inspection-modal').on('click', function(e) {
    e.preventDefault();
    asyncLoadInspectionModal(this);
  });

  // Direct link to inspection
  if($('.js-show-inspection-on-load').size() > 0) {
    asyncLoadInspectionModal('.js-show-inspection-on-load');
  }

  // Revert to the initial reports url when the modal is closed
  if($('.inspection-modal-lg').size() > 0) {
    var initial_history_path = window.location.pathname;
    $('.inspection-modal-lg').on('hide.bs.modal', function (e) {
      window.history.replaceState("Inspection", "Inspection", initial_history_path);
    });
  }

  // Infinite scroll
  if($(".js-infinite-scroll").size() > 0) {
    var isLoading = false;

    $(window).scroll(function(event) {
      $('.pagination').hide();
      var more_inspections_url = $('.pagination a.next_page').attr('href');

      if (!isLoading && more_inspections_url !== undefined && $(window).scrollTop() >= $(document).height() - $(window).height() - 100) {
        isLoading = true;
        $.ajax({
          url: more_inspections_url,
          dataType: "script",
          success: function() {
            isLoading = false;
          }
        });
      }
    });
  }

  // Extras
  $(".js-modal-link").on("click", function(e) {
    e.preventDefault();
  });

  // Web Inspections
  $(".js-not-applicable").on("click", function(e) {
    e.preventDefault();
    var lineItemId = $(this).data("line-item-id");
    var lineItem = $("#" + lineItemId);

    if (lineItem.hasClass("disabled")) {
      // noop - not supported. Could re-enable the n/a line item
      lineItem.removeClass("disabled");
      lineItem.find('input, textarea, button, select').attr("disabled", false);
    } else if (confirm($(this).data("confirm-message"))) {
      lineItem.addClass("disabled");
      lineItem.find('input, textarea, button, select').attr("disabled", true);
    }
  });

  // Used when removing an item from a form
  // Sets the destroy attribute so it is removed.
  $(".js-remove-link").click(function(e) {
    $(this).prev("input[type=hidden]").val("1");
    $(this).closest(".fields").hide();
    return false;
  });

  // Turn on hightag highlighting
  $(".js-hashtag-highlight").highlight("#open");
  $(".js-hashtag-highlight").highlight("#pending");
  $(".js-hashtag-highlight").highlight("#resolved");
  $(".js-hashtag-highlight").highlight("#resolve");
});


// Add Category to inspection forms page
window.add_category = function(html) {
  $(".remove_categories").show().removeClass('hidden');

  if ($(".inspection_form_elements .category").length > 0) {
    $(".inspection_form_elements").append(html);
  } else {
    $(".inspection_form_elements").prepend($(html).addClass("first"));
  }
};

// Removing form elements
window.remove_fields = function(link) {
  $(link).prev("input[type=hidden]").val("1");
  $(link).closest(".line-item").
    hide(). // remove from user's view
    appendTo(".js-form-items"); // move so the css highlighting works
};

window.add_fields = function(link, association, content) {
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_" + association, "g");
  $(".js-form-items").append(content.replace(regexp, new_id));
};


