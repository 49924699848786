/*** IMPORTS FROM imports-loader ***/
var d3 = require("d3");
var define = false;
var exports = false;

$(function() {
  function processReportExport(selector) {
    var reportExportId = $(selector).data('report-export-id');
    var checkStatus = {};

    var updateProgress = function(data, textStatus, jqXhr) {
      var report_export = data['report_export'];
      var downloadUrl = report_export.url;
      var failure = report_export.failure;

      if (failure != undefined) {
        // Hide loader, show failure
        $('.loading-lg').removeClass("loading-lg");
        $(selector).find('.report-export-loading').addClass('hidden');
        $(selector).find('.report-export-failed').removeClass('hidden');
        $(selector).find('.failure-message').text(failure);
      }
      else if (downloadUrl != undefined) {
        // Redirect the user's browser to the downloaded file
        window.location.href = downloadUrl;

        // Update links so user can click Download if redirect doesn't work
        $(selector).find(".download-link").attr('href', downloadUrl);
        $('.loading-lg').removeClass("loading-lg");
        $(selector).find('.report-export-loading').addClass('hidden');
        $(selector).find(".report-export-success").removeClass('hidden');
      } else {
        setTimeout(function() { checkStatus.callback() }, 3000);
      }
    };

    var showError = function(jqXhr, textStatus, errorThrown) {
      $('.loading-lg').removeClass("loading-lg");
      $(selector).find('.report-export-loading').addClass('hidden');
      $(selector).find('.report-export-failed').removeClass('hidden');      
      console.log(errorThrown);
    };

    checkStatus.callback = function() {
      $.ajax({
        url: "/api/v4/report_exports/" + reportExportId,
        dataType: 'json',
        success: updateProgress,
        error: showError
      });
    };

    setTimeout(function() { checkStatus.callback() }, 1000);
  }

  // Attaches to the loading item
  $(".js-report-export").each(function(index, selector) {
    processReportExport(selector);
  });
});

