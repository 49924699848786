/*** IMPORTS FROM imports-loader ***/
var d3 = require("d3");
var define = false;
var exports = false;

window.tbAlert = function (text, level='success') {
  alertBox = $(`.js-alert-box .alert-${level}`)
  alertBox.find(".js-alert-message").text(text)
  alertBox.show();
}

