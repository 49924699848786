/*** IMPORTS FROM imports-loader ***/
var d3 = require("d3");
var define = false;
var exports = false;

import { findDOMNode } from "react-dom"
import { DragDropContext, DropTarget, DragSource } from "react-dnd"
export { SectionDND, LineItemDND }

function validateBounds(dragIndex, hoverIndex, component, clientOffset, options={}) {
  // Determine rectangle on screen
  const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

  let boundingRectBottom
  if(options.height)
    boundingRectBottom = hoverBoundingRect.top + options.height
  else
    boundingRectBottom = hoverBoundingRect.bottom

  // Get vertical middle
  const hoverMiddleY = (boundingRectBottom - hoverBoundingRect.top) / 2

  // Get pixels to the top
  const hoverClientY = clientOffset.y - hoverBoundingRect.top

  // Only perform the move when the mouse has crossed half of the items height
  // When dragging downwards, only move when the cursor is below 50%
  // When dragging upwards, only move when the cursor is above 50%

  // Dragging downwards
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
    return false
  // Dragging upwards
  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
    return false

  return true
}

function isDroppable(props, monitor, component) {
  let item = monitor.getItem()
  let dragIndex = item.index
  let hoverIndex = props.index
  let hoverType = props.lineItem ? "LineItem" : "Section"

  // Don't replace items with themselves
  if(dragIndex === hoverIndex)
    return false

  if(hoverType === "LineItem") {
    if(!validateBounds(dragIndex, hoverIndex, component, monitor.getClientOffset()))
      return false
    // Don't drop a sections into other section's line items
    if(item.lineItemKeys && props.nested)
      return false
    // Don't drop a section into its own line items
    if(item.lineItemKeys && item.lineItemKeys.find(key => key === props.lineItem.key))
      return false
  }

  if(hoverType === "Section") {
    if(props.lineItems.find(lineItem => lineItem.key === item.key))
      return false
    // Don't drop a line item into its own section
    if(!validateBounds(dragIndex, hoverIndex, component, monitor.getClientOffset(), { height: 75 }))
      return false
  }

  return true
}

const LineItemDND = (LineItem) => {
  let config = {
    source: {
      beginDrag(props) {
        return {
          key: props.lineItem.key,
          index: props.index
        }
      },

      isDragging(props, monitor) {
        return props.lineItem.key == monitor.getItem().key
      }
    },

    target: {
      hover(props, monitor, component) {
        let item = monitor.getItem()
        let dragIndex = item.index
        let hoverIndex = props.index

        if(!isDroppable(props, monitor, component))
          return

        let newIndex
        if(item.lineItemKeys)
          newIndex = props.onMove(dragIndex, hoverIndex, item.lineItemKeys.length)
        else
          newIndex = props.onMove(dragIndex, hoverIndex)

        monitor.getItem().index = newIndex
      }
    }
  }

  LineItem = DragSource("LineItem", config.source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }))(LineItem)

  LineItem = DropTarget(["LineItem", "Section"], config.target, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget()
  }))(LineItem)

  return LineItem
}


const SectionDND = (Section) => {
  let config = {
    source: {
      beginDrag(props) {
        return {
          key: props.section.key,
          index: props.index,
          lineItemKeys: props.lineItems.map(lineItem => lineItem.key)
        }
      },

      isDragging(props, monitor) {
        return props.section.key == monitor.getItem().key
      }
    },

    target: {
      hover(props, monitor, component) {
        let item = monitor.getItem()
        let dragIndex = item.index
        let hoverIndex = props.index

        if(!isDroppable(props, monitor, component))
          return

        let newIndex
        if(item.lineItemKeys)
          if(dragIndex < hoverIndex)
            // If we're dragging a section down after another section,
            // we need to insert it after the hover section's last line item
            newIndex = props.onMove(dragIndex, hoverIndex + props.lineItems.length + 1, item.lineItemKeys.length)
          else
            newIndex = props.onMove(dragIndex, hoverIndex, item.lineItemKeys.length)
        else
          newIndex = props.onMove(dragIndex, hoverIndex)

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = newIndex
      }
    }
  }

  Section = DragSource("Section", config.source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }))(Section)

  Section = DropTarget(["LineItem", "Section"], config.target, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget()
  }))(Section)

  return Section
}


