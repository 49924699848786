/*** IMPORTS FROM imports-loader ***/
var d3 = require("d3");
var define = false;
var exports = false;

import moment from "moment"

$(function() {
  // Used to toggle the default choice on range ratings
  $('.js-rating-defaults').on('change', '.js-toggle-radio', function() {
    // set the default value for all the other radio buttons
    $(".js-toggle-radio-value").val('f');
    // set the value of the closest radio button
    $(this).prev('.js-toggle-radio-value').val('t');
  });

  $(".js-sortable-list").sortable({
    containment: '.js-sortable-list',
    tolerance: 'pointer',
    items: ".js-sortable-item"
  });

  // Sets position on each choice after user presses save
  $(".js-set-position-form").submit(function(e) {
    if ($(this).hasClass("js-submitted")) {
      return true;
    } else {
      e.preventDefault()

      $(".js-sortable-item").each(function(i, lineItem) {
        $(lineItem).find(".js-sortable-position").val(i);
      });

      $(this).addClass("js-submitted")
      $(this).submit()
    }
  });

  $(".js-enable-list-choice").click(function(e) {
    e.preventDefault()

    var selector = $(this).data("selector")
    $("#" + selector).val("");

    $(this).addClass("hidden");
    $(this).parent().find(".js-disable-list-choice").removeClass("hidden");
    $(this).parents(".list-group-item").removeClass("list-group-item-grey");
  });

  $(".js-disable-list-choice").click(function(e) {
    e.preventDefault()

    var selector = $(this).data("selector")

    $("#" + selector).val(moment());

    $(this).addClass("hidden");
    $(this).parent().find(".js-enable-list-choice").removeClass("hidden");
    $(this).parents(".list-group-item").addClass("list-group-item-grey");
  });

  $(".js-edit-list-choice").click(function(e) {
    e.preventDefault()
    $(this).addClass("disabled");
    if(confirm("Are you sure you want to edit this choice? Editing the choice will modify historical data. We recommend disabling this choice and creating a new one instead.")) {
      var selector = $(this).data("selector")
      $("#" + selector).removeClass("hidden");
      $("#" + selector).siblings(".js-display-name").addClass("hidden");
    }
  });
});

